.rigid-flex-settings-card{
  min-width: 550px;
  .technical-data-card{
    width: 100%;
  }
}



/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  .rigid-flex-settings-card{
    min-width: auto;
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .rigid-flex-settings-card{
    .technical-data-card{
      width: 100%;
    }
  }
}