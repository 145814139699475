@import '../../icape-da';

.lab-services-toolbar{
  justify-content: flex-end;
}
.lab-services-menu{
  width: 40%;
  display: flex;
  position: sticky;
  height: fit-content;
  top: 130px;
}

.lab-services-menu-inner{
  display: flex;
  gap: 20px;

  .drop-bloc{
    width: 50%;
    min-width: auto;
  }
}
.lab-services-menu-inner-switcher{
  width: 50%;
}

.lab-services-table{
  width: 60%;

  .MuiTableContainer-root{
    height: 365px;
    overflow-y: auto;
  }
}
.lab-services-table-toolbar{
  position: absolute;
}
.lab-services-table-usa-menu{
  border-bottom: 1px solid $softBlue;
  margin-top: -18px;
  Button{
    color: $black;
    font-weight: 600;
    text-transform: none;
    font-size: 18px;
    padding-top: 0;
    padding-bottom: 0;
    font-family: Montserrat, sans-serif;
    letter-spacing: 0;
  }
  Button.Mui-selected{
    color: $softBlue;
  }
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
  .MuiTabs-flexContainer{
    gap:20px;
  }
  .MuiTabs-indicator{
    height: 4px;
    background-color: $softBlue;
  }
}
.lab-services-table-row th, .lab-services-table-row td{
  border-bottom: none
}
.lab-services-table-inner{
  > .MuiBox-root{
    padding: 0;
  }
}
.lab-services-collapse-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;

  > div{
    background-color: $softBlue3;
    /*width: 350px;*/
    padding: 10px;
    border-radius: $border-radius;
  }
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  .lab-services-menu{
    width: 35%;
  }
  .lab-services-menu-inner{
    flex-direction: column-reverse !important;
    .drop-bloc{
      width: 100%;
    }
  }
  .lab-services-menu-inner-switcher{
    width: 100%;
  }
  .lab-services-table{
    width: 65%;
    .MuiTableContainer-root{
      height: auto;
    }
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .lab-services-menu{
    position: static;
    width: 100%;
    flex-direction: column-reverse !important;
  }
  .lab-services-menu-inner{
    flex-direction: column !important;
  }
  .lab-services-table{
    width: 100%;
  }
  .lab-services-table-toolbar{
    position: static;
  }
}