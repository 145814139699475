@import '../../icape-da';


/*----- LOGIN -----*/
.login-part {
  width: 450px;
  height: 550px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > span{
    font-size: 14px;
    color: $blueIcape;
  }
}
.form-wrapper {
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-top: 30px;
  gap: 20px;
}
.login-cta{
  margin-top: 30px;
}
.redirectionLinkContainer{

  margin-top: 50px;

  .redirectionLink{
    user-select: none;
    display: flex;
    width: 100%;
    gap: 10px;
    font-size: 14px;
    > span {
      cursor: pointer;
      color: $darkGreen;
      font-weight: 600;

      &:hover{
        color: $green;
      }
    }
  }
  .redirectionLink:last-of-type{
    margin-top: 5px;
  }
}

.hide {
  display: none;
}


/*----- REGISTER -----*/
.register-part {
  padding: 50px;
  max-width: 800px;
}
.register-title{
  margin: 30px 0;
  span{
    color: $softBlue;
  }
}
.register-fields-wrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px ;

  .form-spacer{
    width: 300px;
  }

  .MuiFormControl-root{

  }
}
.register-cta{
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px 0;
}
.register-terms-services{
  display: flex;
  align-items: start;
  flex-direction: column;

  margin-top: 30px;

  label{
    margin-right: 0;
  }

  a{
    font-size: 14px;
    font-weight: 600;
    color: $softBlue;
    margin-left: 25px;
  }
}
.login-switch{
  user-select: none;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  font-size: 14px;
  > span {
    cursor: pointer;
    color: $darkGreen;
    font-weight: 600;

    &:hover{
      color: $green;
    }
  }
}
.register-result{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  max-width: 500px;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .login-part {
    max-width: calc(100vw - 20px);
  }
}