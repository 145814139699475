@import '../icape-da';

/*----- GLOBAL -----*/
.calculator-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  svg{
    font-size: 25px;
  }
}
.calculator{
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 30px 50px 50px 50px;
  margin: 0 auto 150px auto;
  h1{
    align-self: start;
    margin-bottom: 20px;
  }
  h3{
    color: $softBlue;
  }
}
.calculator-repeat-order{
  background: linear-gradient(0deg, rgba(230,242,248,0) 30%, rgba(79,67,135,0.2) 100%);
}
.no-project-panel{
  margin: auto auto 150px;
  max-width: 1440px;
}
.color-green {
  color: $green;
}
.color-blue {
  color: $blueIcape;
}


/*----- REPEAT ORDER -----*/
.repeat-order-mode{
  width: 100%;
}
.repeat-order-main-title{
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  svg {
    fill: $softBlue;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      fill: $blueIcape;
    }
  }
}

/*----- STEPPER -----*/
.stepperBlock {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: $border-shadow;
  padding: 10px;
  border-radius: $border-radius;

  .MuiStepIcon-root.Mui-active{
    color: $blueIcape;
  }
  .MuiStepIcon-root.Mui-completed{
    color: $green;
  }
}
.stepper-loading-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  transition: all .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  height: 0;
  z-index: 1;
  >span{
    color: $darkGreen;
    font-size: 14px;
    font-weight: 600;
  }
  >Div{
    flex-grow: 2;
  }
}
.show-stepper-bar{
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  height: 30px;
  margin-top: 10px;
}


/*----- TOOLBAR -----*/
.title-wrapper{
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 0 0;
  >h2{
    align-self: end;

    span{
      color: $darkBlue;
    }
  }
}
.calculator-toolbar{
  background-color: $white;
  height: auto;
  padding: 15px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-radius: $border-radius;
  box-shadow: $border-shadow;
  border: 2px solid $softBlue2;

  .MuiToggleButton-root{
    justify-content: center;
    width: 60px;
    text-transform: lowercase;

    &.Mui-selected:after{
      content: "";
    }
  }
}
#error-pcb-area{
  color: $red;
  font-weight: 600 !important;
  align-self: flex-end;
  margin-top: 20px;
}
.icon-description-wrapper{
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
}


/*----- STEP RENDERER -----*/
.content-wrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;

  .lock-repeat-order{
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    background-color: $darkBlue;
    border-top-right-radius: $border-radius;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    display: flex;
    justify-content: flex-end;
    padding: 15px;

    svg{
      fill: white;
      font-size: 30px;
    }
  }
}
.technical-data-card {
  /*height: fit-content;*/
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 20px 20px 20px;
  border-radius: $border-radius;
  box-shadow: $border-shadow;
  transition: box-shadow 0.3s ease-in-out;
}
.technical-data-card:hover{
  box-shadow: $offsetX $offsetY $blur rgba(53,53,53,.3);
}
.technical-data-card-column{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.technical-data-card-column-fields{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}


/*----- DRAG N DROP -----*/
.drop-bloc {
  display: flex;
  flex-direction: column;
  width: 320px;
  position: relative;

  .tooltip-gerber{
    width: fit-content;
    position: absolute;
    top: 20px;
    right: 10px;
  }
}

.gerber-help-icon{
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  svg {
    fill: $softBlue;
    font-size: 25px;

    &:hover{
      fill: $darkBlue;
    }
  }
}
.drop-bloc[data-isOn="true"] {
  width:50%;
}
.archive-bloc{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  border: 2px solid $blueIcape;
  border-radius: $border-radius;

  img{
    width: 50px;
  }
  svg{
    fill: $blueIcape;
  }
}
.dropzone {
  //background-color: darkgreen;
  width: 100%;
  height:100%;
  min-height: 140px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;

  span{
    font-size: 14px;
  }

  .dropzone-text-small{
    font-size: 12px;
    color: $darkBlue;
  }

}
.drop-off{
  //cursor: no-drop;
  cursor: auto;
}


/*----- FIELDS UNITS -----*/
.MuiInputBase-root{
  &.Mui-error{
    .MuiInputAdornment-root{
      p{
        color: $red;
      }
    }
  }
}
.MuiInputAdornment-root{
  p{
    color: $green;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
  }
}
.select-input-adornment{
  /*margin-right: 20px;*/
  position: absolute;
  right: 35px;
}


/*----- FIELDS COLORS -----*/
.color-dot-wrapper{
  display: flex;
  width: 100%;
  align-items: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.color-dot{
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-right: 10px;
}

.color-dot-green{
  background-color: $softGreen;
}
.color-dot-matte-green{
  background-color: $darkGreen;
}
.color-dot-blue{
  background-color: $blueIcape;
}
.color-dot-matte-blue{
  background-color: $darkBlue;
}
.color-dot-black{
  background-color: $black;
}
.color-dot-matte-black{
  background-color: $grey;
}
.color-dot-white{
  border: 1px solid $grey;
  background-color: $white;
}
.color-dot-red{
  background-color: $red;
}
.color-dot-yellow{
  background-color: $yellow;
}


/*----- STENCIL SETTINGS CROSS CALCULATORS-----*/
.step-3-options{
  max-width: 350px;
}
.no-stencil-container{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  color: $softBlue;
  top: 0;
  left: 0;

  svg {
    font-size: 150px;
    fill: $softBlue3;
  }
}

/*----- DELIVERY SETTINGS CROSS CALCULATORS-----*/
.delivery-form-settings{
  flex-grow: 1;
  h3{
    width: 100%;
  }
  .calendar-helper-info{
    font-size: 14px;
    color: $purple;
    font-weight: 600;
  }
}
.delivery-picker-wrapper{
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-grow: 1;
  .MuiDateCalendar-root{
    border: 2px solid $softBlue;
    border-radius: $border-radius;
    flex-grow: 2;
  }
}
.delivery-selector-info{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  flex-grow: 1;

  .MuiFormControl-root{
    width: 100%;
    max-width: 300px;
  }
}
.delivery-box-fields, .delivery-box-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  flex-wrap: wrap;
}
.delivery-box-info{
  gap: 10px;
}
.MuiPickerStaticWrapper-root {
  //box-shadow: $border-shadow !important;
  border: solid 1px $softBlue;
  border-radius: 5px;
}

.legend-line {
  display: flex;
  align-items: center;
  gap: 10px;
  span{
    font-size: 14px;
  }
}
.legend-square{
  width:16px;
  height: 16px;
  border-radius: 10px;
}
.production-time{
  background-color: $blueIcape;
}
.days-to-delivery{
  background-color: $shipping-days-color;
}
.daysOff{
  background-color: $darkRed;
}
.estimated-delivery-date{
  background-color: $green;
}
.working-days-legend, .shipping-days-legend {
  font-weight:bold;
  color:$white;
  padding-left: 5px;
}
.working-days-legend {
  color: $working-days-color;
}
.shipping-days-legend {
  color: $shipping-days-color;
}
.delivery-date-legend {
  color:$black;
  font-weight:bold;
}

.customer-info  {
  font-size: 12px;
  color:$purple;
  display:flex;
  align-items: center;
  column-gap: 5px;
  user-select: none;
  margin-top: auto;
}
.project-reference-info {
  margin-top: 0;
  cursor: pointer;

  &:hover{
    color: $softPurple;
  }
}


/*----- CTA NEXT / ORDER -----*/
.step-cta-desktop{
  margin-top: 50px;
  align-self: end;
  display: flex;
  gap: 30px;
}
.step-cta-mobile{
  margin: 50px auto 200px auto;
  display: none;
}


/*----- JSAIS PO CKE C -----*/
.alertTitle{
  color: $blueIcape;
  font-weight: bold;

}
.repeatOrder-lastOrdered {
  color: crimson;
  font-size: 9px;
  margin:0 10px;
}



/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  .calculator-wrapper{
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  .calculator{
    margin-bottom: 0;
  }

  .title-wrapper{
    width: 100%;
    flex-direction: column;

    >h2{
      width: 100%;
    }
  }

  .delivery-form-settings {
    width: 100%;
    min-width: unset;
  }
  .delivery-picker-wrapper{
    flex-direction: column;
  }

  .step-cta-desktop{
    align-self: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .step-cta-mobile{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .calculator{
    padding: 50px 10px;
  }
  .content-wrapper {
    flex-direction: column;
  }
  .calculator-toolbar{
    flex-wrap: wrap;
  }
  .step-3-options{
    max-width: unset;
  }
}