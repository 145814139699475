@import '../icape-da';

.missing-route-container{
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 50px;
  text-align: center;

  h1{
    color: $black;

    span {
      color: $softBlue;
      font-weight: 600;
    }
  }
}