@import '../../../../icape-da';

//COLORS
$v-cut: $darkWhite;
$milling: $black;
$panel: $softGrey2;
$pcb: linear-gradient(25deg, rgba(148,203,110,1) 0%, rgba(186,225,159,1) 100%);

//PANEL SETTINGS
.panel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $panel;
  position: relative;
}
.pcb-row{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $panel;
}
.pcb {
  background: $pcb;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.panel-size-x, .panel-size-y{
  .error-size{
    font-weight: 600;
    color: $red;
  }
}
.panel-size-x{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  position: absolute;
  bottom: -40px;
}
.panel-size-y{
  height: 100%;
  width: 20px;
  display: flex;

  position: absolute;
  left: -40px;

  .auto-arrow{
    flex-direction: column;
  }

  .panel-height{
    height: 100%;
    display: flex;
    align-items: center;
  }

  span{
    transform: rotate(-90deg);
    transform-origin: 25px 25px;
    width: 20px;
  }
}
.auto-arrow{
  display: flex;
  width: 100%;
  align-items: center;

  .horizontal-line{
    width: 100%;
    height: 2px;
    background-color: $blueIcape;
  }
  .vertical-line{
    height: 100%;
    width: 2px;
    background-color: $blueIcape;
  }
  svg{
    font-size: 20px !important;
    color: $blueIcape;
  }
}

//V CUT
.separation.row, .separation.column {
  background-color: $v-cut;
  position: absolute;
  z-index: 20;
  transition: all 0.3s ease-in-out;
}
.separation.row {
  width: 100%;
  height: 2px;
}
.separation.column {
  width: 2px;
  height: 100%;
}

//MILLING
.milling-top, .milling-bottom {
  display: flex;
  width: 100%;
  background-color: $panel;
  position: absolute;

  div{
    flex-grow: 1;
    height: 2px;
    background-color: $milling;
  }
}
.milling-top{
  top: 0;
}
.milling-bottom{
  bottom: 0;
}

.milling-right, .milling-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $panel;
  position: absolute;

  div{
    flex-grow: 1;
    width: 2px;
    background-color: $milling;
  }
}
.milling-right{
  right: 0;
}
.milling-left{
  left: 0;
}