@import "../icape-da";

/* ------ GLOBAL HEADER----- */
.header{
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $white;
    box-shadow: $border-shadow;
    position: sticky;
    top: 0;
    z-index: 1000;
}


/* ------ TOP BAR ----- */
.top-bar-wrapper{
    background-color: $black;
    width: 100%;
    height: 30px;
    z-index: 210;
}
.top-bar-container{
    width: 100%;
    max-width: calc(1440px - 300px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    background-color: $black;
    color: #FFFFFF;
    margin: auto;
    padding: 0;

    a:hover{
        color: $blueIcape;
    }
}
.top-bar-items{
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
}
.top-bar-tools{
    display: flex;
    gap: 30px;
    justify-content: flex-end;
    align-items: center;
    svg{
        fill: #299FDE;
    }
 }
.tools-with-icon{
    display: flex;
    align-items: center;
    gap: 5px;
}
.secondary-dropdown-container{
    height: 100%;
    display: flex;
    align-content: center;
    gap: 10px;
    position: relative;

    cursor: pointer;

    span{
        font-size: 12px;
        font-weight: 600;
        color: $white;
    }

    .active-menu{
        color: $blueIcape;
    }
    .menu-items{
        position: absolute;
        left: -45px;
        top: 30px;
        background-color: $white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 120px;
        cursor: pointer;
        box-shadow: $border-shadow;
        z-index: 100;

        .disabled{
            display: none;
        }
        span{
            font-size: 12px;
            font-weight: 600;
            color: $black;

            &:hover{
                color: $softBlue;
            }
        }
    }
    .user-menu{
        left: 0;
        width: 160px;

        .user-menu-items{
            display: flex;
            gap: 10px;
            align-items: center;

            svg{

            }
        }
    }
}
.secondary-dropdown-container-mobile{
    display: flex;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
    cursor: pointer;

    .dropdown-items{
        width: 100%;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;


        .user-menu-items-mobile{
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
        .disabled{
            display: none;
        }
        span{
            font-weight: 600;
            color: $white;

            &:hover{
                color: $softBlue;
            }
        }
    }

    svg{
        fill: #299FDE;
    }
}

/* ------ NAV BAR ----- */
.nav-bar-wrapper{
    width: 100%;
    height: 80px;
    z-index: 200;
    background-color: $white;
    display: flex;
    justify-content: center;
}
.nav-bar{
    width: 100%;
    max-width: 1240px;
    //max-width: calc(1440px - 300px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}
.nav-bar-menu{
    display: flex;
    height: 100%;
    align-items: center;
    gap: 30px;
    span{
        display: flex;
        align-items: center;
        color: $darkBlue2;
        font-weight: 600;
        cursor: pointer;
        height: 100%;
    }
    .MuiTypography-root{
        display: flex;
    }
}
.nav-bar-menu-item{
    height: 100%;
    position: relative;
}

.dropdown-menu-section-multiple-wrapper{
    position: fixed;
    left: 0;
    width: 100vw;
    background-color:  $white;
    box-shadow: 0 4px 6px -6px #222;
    display: flex;
    justify-content: center;
}
.dropdown-menu{
    display: flex;
    padding: 20px;
    span:first-child{
        font-size: 18px;
        color: $darkBlue;
        font-weight: bold;
        margin-bottom: 15px;
        height: auto;
    }
    a{
        color: $softBlue;
        font-weight: 400;
        padding: 2px 0;
        &:hover{
            color: $darkBlue;
        }
    }
}
.dropdown-menu-section-multiple{
    align-items: flex-start;
    .section-multiple-links{
       /* height: 200px;*/
        padding: 0 50px;
        border-right: 1px solid $softBlue3;

        &:last-child{
            border-right: none;
        }

        .one-column{
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
        .two-column{
            display: flex;
            flex-wrap: wrap;
            max-width: 400px;
            a{
                width: 200px;
                margin-bottom: 3px;
            }
        }
        .small-two-column{
            max-width: 250px;
            a{
                width: 125px;
            }
        }
    }
}
.dropdown-menu-section-solo{
    position: absolute;
    left: -30px;
    width: fit-content;
    flex-direction: column;
    gap: 3px;
    min-width: 220px;
    background-color:  $white;
    box-shadow: 0 4px 6px -6px #222;
}

#burger-menu-icon{
    display: none;
    color: $softBlue;
    margin-left: 0;
    padding: 0;

    :hover{
        color: $softBlue2;
    }
}
.mobile-menu-wrapper{
    display: none;

    /*DRAWER WIDTH*/
    & .MuiDrawer-paper{
        width: 100%;
        max-width: 500px;
        background-color: $darkBlue3;
        padding: 30px;
    }
}
.mobile-menu{
    color: $white;

    #menu-close-icon{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .menu-subitem-multiple-child{
        color: $softBlue;
        padding: 0;
        span{
            font-size: 18px;
            font-weight: 600;
        }
    }
    .menu-subitem-button-multiple-child-list{
        li{
            padding: 5px 0;
        }
        li:hover{
            color: $softBlue;
        }
    }

    .menu-item-button{
        padding: 10px 0;
        span{
            font-size: 22px;
            font-weight: 600;
        }

    }
    .menu-subitem-button{
        padding: 0;
        span{
            font-size: 16px;
            font-weight: 400;
        }

    }
    .menu-item-button:hover,
    .menu-subitem-button:hover{
        color: $softBlue;
    }

    hr{
        background-color : $softGrey;
    }
    ul{
        padding: 0 0 30px 0;
        hr{
            background-color : $softGrey;
            opacity: 0.3;
        }
        hr:last-child{
            display: none;
        }
    }

    .mobile-menu-top-bar{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 50px 0 200px 0;
        font-size: 22px;
        font-weight: 600;

        :hover{
            color: $softBlue;
        }
    }
}


/* ------ ANNOUNCEMENT BAR ----- */
.announcement-bar-wrapper{
    width: 100%;
    z-index: 199;
}
.announcement-bar{
    width: 100%;
    max-width: calc(1440px - 500px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin: auto;
    text-align: center;
    height: auto;
    padding: 5px 0;
}
.blue-bar {
    background-color: $blueIcape;
    color: $white;
    height: 35px;

    span {
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
}
.yellow-bar {
    background-color: $yellow;
    color: $black;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
    /* ------ TOP BAR ----- */
    .top-bar-wrapper{
        display: none;
    }

    /* ------ NAV BAR ----- */
    .nav-bar{
        padding: 0 30px;
        button{
            display: none;
        }
    }
    .nav-bar-menu {
        display: none;
    }
    #burger-menu-icon, .mobile-menu-wrapper {
        display: block;
    }
}

/* ------ TABLET ARESSY------ */
@media screen and (max-width: 720px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 480px) {
    .yellow-bar {
        height: 55px;
    }
}