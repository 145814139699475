@import "../icape-da";

/* ------ GLOBAL LOADING ----- */
.loaging-container{
  width: 100%;
  height: 100vh;
  background-color: $white;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  span{
    color: $white;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}