@import './icape-da';

*{
 /* text-transform: capitalize;*/
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  /*color: $black;*/
}

body{
  background: rgb(230,242,248);
  background: linear-gradient(0deg, rgba(230,242,248,1) 30%, rgba(255,255,255,1) 100%);
  /*background: linear-gradient(0deg, $softBlue3 30%, $white 100%);*/
  /*background-image: url("../../public/background.png");*/
}

a{
  color: inherit;
  cursor: pointer;
}

.video-container {
  width: 100%;
  max-width: 799px;
  overflow: hidden;

}
.video-container video {
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
}

/*Connect to backend linear progress*/
.connect-bo-progress-bar{
  width: 100%;
  position: fixed;
  top: 110px;
  left: 0;
  z-index: 100;

  .MuiLinearProgress-root{
    top: 0;
  }
}

/*avoid scrolling into filter windon in datagrid*/
.MuiFormControl-root > .MuiTextField-root {
  width: unset;
}

.MuiInputBase-input, .MuiFormHelperText-root{
  text-transform: none;
}


//MUI Chip BG colors
.green {
  background-color: $darkGreen !important;
}
.blue {
  background-color: $darkBlue !important;
}
.purple {
  background-color: $purple !important;
}
.grey{
  background-color: $grey !important;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: $softBlue2;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $softBlue;
}


/* ------ MOBILE ------ */
@media screen and (max-width: 1200px) {
  .connect-bo-progress-bar{
    top: 80px;
  }
}