
.popup-background{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 5000;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.popup{
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  max-width: 600px;

  h2{
    span{
      color: #007DC8;
    }
  }

  .content{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    span{
      font-weight: 600;
    }

    a{
      color: #007DC8;
      text-decoration: underline;
    }
  }
}