@import '../icape-da';

/* ------ GLOBAL ------ */
.user-account-wrapper{
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 50px;
  max-width: 1920px;
  margin: auto auto 200px auto;
  position: relative;
}

.MuiDataGrid-root .MuiDataGrid-row{
  cursor: pointer;
}

/* ------ SIDEBAR ------ */
.user-sidebar-wrapper{
  width: 100%;
  max-width: 350px;
  height: fit-content;
  position: sticky;
  top: 150px;

  .MuiAvatar-root{
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
    background-color: $softGreen;

    svg{
      width: 90%;
      height: 90%;
    }
  }
  .MuiDivider-root{
    border-color: $softBlue;
    margin: 30px 0;
  }
}
.user-sidebar-avatar{
  display: flex;
  flex-direction: column;
  align-items: center;

  h1{
    color: $blueIcape;
    text-align: center;
  }
  #ac-verified{
    color: $darkGreen;
    display: flex;
    gap: 5px;

    svg{
      width: 20px;
    }
  }
  #ac-not-verified{
    color: $darkRed;
    display: flex;
    gap: 5px;

    svg{
      width: 20px;
    }
  }
}
.user-sidebar-sales-info{
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2{
    margin-bottom: 10px;
  }

  span{
    display: flex;
    gap: 10px;

    svg{
      fill: $blueIcape;
    }
  }
}
.user-sidebar-gdpr{
  display: flex;
  flex-direction: column;
  gap: 10px;

  span{
    cursor: pointer;
    width: fit-content;
  }

  span:first-child{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    color: $softBlue;
    font-weight: 500;

    &:hover{
      color: $blueIcape;
    }
  }
  span:last-child{
    font-size: 12px;
    color: $softGrey2;

    &:hover{
      color: $softGrey;
    }
  }
}


/* ------ CONTENT ------ */
.user-content-wrapper{
  width: 100%;

  button{
    justify-self: flex-end;
  }
  .MuiTabs-indicator{
    height: 4px;
    background-color: $green;
  }
  .MuiBox-root{
    padding: 0;
  }
  .MuiDivider-root{
    border-color: $softBlue;
    margin: 50px 0;
  }
}
.user-content-menu{
  border-bottom: 1px solid $green;
  margin-bottom: 10px;

  Button{
    color: $black;
    font-weight: 600;
    text-transform: none;
    font-size: 21px;
    font-family: Montserrat, sans-serif;
    letter-spacing: 0;
  }
  Button.Mui-selected{
    color: $black;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  .MuiTabs-flexContainer{
    gap:20px;
  }
}

.user-content-field-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  h3{
    color: $blueIcape;
  }
}
.user-content-field{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .MuiFormControl-root{
    width: calc(50% - 10px);
  }
}
.user-content-switch-address{
  display: flex;
  align-items: center;
  color: $black;
}
.user-content-switch-address-icon{
  cursor: pointer;
  svg{
    fill: $softBlue;
  }
}

.user-content-field-double-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  span{
    /*alrick c'est chimique ca, je sais*/
    margin-bottom: -10px;
  }
}
.user-content-field-double{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width:  calc(50% - 10px);

  .MuiTextField-root{
    width: 100%;
  }
}

.user-content-cta{
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  margin-top: 50px;
}


/* ------ ORDER / QUOTE DETAILS ------ */

.tooltip-icon-blue, .tooltip-icon-red, .tooltip-icon-disabled{
  svg{
    transition: ease-in-out 0.3s;
  }
}
.tooltip-icon-blue{
  svg{
    color: $softBlue;
  }

  &:hover{
    svg{
      color: $darkBlue;
    }
  }
}
.tooltip-icon-red{
  svg{
    color: $red;
  }

  &:hover{
    svg{
      color: $darkRed;
    }
  }
}
.tooltip-icon-purple{
  svg{
    color: $softPurple;
  }

  &:hover{
    svg{
      color: $purple;
    }
  }
}
.tooltip-icon-disabled{
  svg{
    color: $grey;
  }
}

.table-pcb-details-wrapper{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  &:last-of-type{
    margin-top: 30px;
  }
}
.table-pcb-details{
  flex: 1 1 350px;

  border-right: solid $darkBlue 2px;

  &:last-of-type{
    border-right: none;
  }

  table{
    margin-top: 10px;
    width: 100%;

    tr td:first-child{
      width: 65%;
    }

    tr td:last-child{
      color: $darkBlue;
      font-weight: bold;
    }
  }
}

.user-order-detail-wrapper{
  display: flex;
  flex-wrap: wrap;
}
.user-order-detail-title{
  width: 100%;
  display: flex;
  justify-content: space-between;

  h3{
    span{
      color: $black;
    }
  }

  svg{
    font-size: 30px;
    fill: $blueIcape;
    cursor: pointer;

    &:hover{
      fill: $darkBlue;
    }
  }
}
.user-order-tab-resume-wrapper{
  width: 100%;
  display: flex;
  margin-top: 50px;
  overflow-x: scroll;

  .user-order-tab-resume-column{
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: solid $blueIcape 1px;
    border-top: none;
    border-left: none;

    h4{
      width: 100%;
      border-bottom: solid $blueIcape 1px;
      padding: 20px;
      margin-bottom: 15px;
      white-space: nowrap;
    }

    span {
      padding: 0 20px;
      &:last-child{
        padding-bottom: 150px;
      }
    }
  }

  .column1{
    flex-grow: 3;
    border-left: solid $blueIcape 1px;
    min-width: 250px;
  }
  .column2{
    flex-grow: 3;
  }
  .column3{
    flex-grow: 1;
    align-items: flex-end;
  }
  .column4{
    flex-grow: 1;
    align-items: flex-end;
  }
  .column5{
    flex-grow: 1;
    align-items: flex-end;
  }
  .column6{
    flex-grow: 1;
    align-items: flex-end;
  }

  .user-order-tab-resume-total{
    position: absolute;
    display: flex;
    gap: 30px;
    bottom: -40px;
    right: 0;
    padding-right: 10px;

    span{
      font-size: 25px;

      &:last-child{
        font-weight: 500;
      }
    }
  }
}
.user-order-tab-resume-total{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 20px;

  span{
    font-size: 25px;

    &:last-child{
      font-weight: 500;
    }
  }
}
.user-order-infos-wrapper{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  column-gap: 50px;


  .user-order-status-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding: 30px;
    background-color: $softBlue3;
    border-radius: $border-radius;
  }
  .user-order-status{
    flex-direction: column;
    flex-grow: 2;

    h4{
      margin-bottom: 10px;
    }

    table tbody tr td:last-child{
      padding-left: 20px;
    }

    .user-order-status-column-wrapper{
      display: flex;
      gap: 50px;
    }

    .user-order-status-column {
      display: flex;
      flex-direction: column;
    }
  }

  .user-order-addresses-wrapper{
    display: flex;
    padding-top: 30px;
    gap: 30px;
  }
  .user-order-address{
    display: flex;
    flex-direction: column;

    h4{
      margin-bottom: 10px;
    }
  }

  .user-order-delivery{
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: space-between;

    h4{
      margin-bottom: 10px;
    }

    .user-order-delivery-column-wrapper{
      display: flex;
      justify-content: space-between;
    }

    .user-order-delivery-column {
      display: flex;
      flex-direction: column;
    }
  }

}
.user-order-project-files-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;

  .user-order-project-files-title{
    width: 100%;
    display: flex;
    gap: 30px;
    margin-bottom: 10px;

    svg{
      font-size: 20px;
      fill: $blueIcape;
      cursor: pointer;

      &:hover{
        fill: $darkBlue;

      }
    }
  }
  .user-order-project-file{
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;

    >div{
      display: flex;
      gap: 30px;

      span{
        cursor: pointer;

        &:last-child{
          color: $softBlue;

          &:hover{
            color: $blueIcape;
          }
        }
      }

      //TODO UPDATE CE IMPORTANT JE SUIS UNE NUL et pressé...
      .red{
        cursor: auto;
        color: $red !important;

        &hover{
          color: $red !important;
        }
      }
      .red{
        cursor: auto;
        color: $red !important;

        &hover{
          color: $red !important;
        }
      }
    }
  }
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  .user-account-wrapper{
    flex-wrap: wrap;
  }
  .user-sidebar-wrapper{
    max-width: none;
    position: static;
    width: 100%;
  }
  .user-content-wrapper{
    width: 100%;
  }
  .user-content-field{
    .MuiFormControl-root{
      width: 100%;
    }
  }
  .user-content-field-double{
    width: 100%;
  }

  .table-pcb-details-wrapper{
    &:last-of-type{
      margin-top: 20px;
    }
  }
  .table-pcb-details {
    border-right: none;
  }

  .user-order-tab-resume-wrapper{
    .column1{

    };
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {
  .user-account-wrapper{
    padding: 50px 10px;
  }
}