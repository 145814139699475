@import "../icape-da";

/* ------ GLOBAL  ----- */

.checkout-card{
  max-width: 800px;
  margin: 100px auto 200px auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 30px 50px 20px 50px;
  border-radius: $border-radius;
  box-shadow: $border-shadow;
  transition: box-shadow 0.3s ease-in-out;

  h1{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }

  h4{
    span{
      color: $blueIcape;
    }
  }

  span{
    text-align: center;
  }

  button{
    margin: 30px 0 0 0;
  }

  &:hover{
    box-shadow: $offsetX $offsetY $blur rgba(53,53,53,.3);
  }
}
.checkout-icon{
  fill: $red !important;
}

/* ------ CONTENT ----- */

.bank-information-container{
  display: flex;
  flex-direction: column;
  gap: 5px;

  span{
    text-align: left;
  }
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {

}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {

}