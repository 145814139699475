@import '../../icape-da';

/*----- IMAGE -----*/
.background-image {
  border-radius: $border-radius 0 0 $border-radius;
  /*background-image: url("./bg.jpg");*/
  width: 450px;
  height: 550px;
}


/* ------ TABLET ------ */
@media screen and (max-width: 1200px) {
  .background-image {
    display: none;
  }
}

/* ------ MOBILE ------ */
@media screen and (max-width: 700px) {

}