@import "../icape-da";

.quickhelper-container{
  display: none;
  position: fixed;
  bottom: 80px;
  right: 80px;
  z-index: 100;
  text-transform: none;
}
.quickhelper-tablet{
  height: 80px;
  width: 80px;
  background-color: $softBlue;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $border-shadow;
  &:hover{
    background-color: $blueIcape;
  }
}

.quickhelper-menu-container{
  width: 270px;
  background-color: $white;
  position: relative;
  z-index: 2;
  box-shadow: $border-shadow;
}
.quickhelper-close{
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  svg{
    color: $white;

    &:hover{
      color: $darkBlue;
    }
  }
}
.quickhelper-header{
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  background-color: $blueIcape;
  padding: 16px 20px;
  span{
    width: 100%;
    text-transform: none;
  }
  span:first-child{
    display: flex;
    align-items: center;
    color: $softBlue2;
    font-weight: 700;

  }
  span:last-child{
    color: $white;
    font-size: 10px;
  }
}
.quickhelper-items{
  list-style: none;
  border-bottom: 12px solid #299fde;

  li{
    color: $blueIcape;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 20px;
    cursor: pointer;
    height: 48px;
    &:nth-child(2n+1){
      background-color: $softBlue3;
    }
  }
  li:hover::before {
    content: "\27F6";
    color: #007dc8;
    font-size: 16px;
    margin-right: 10px;
  }
}