.technologies {
    display: flex;
    max-width: 1920px;
    gap: 30px;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px 0 150px 0;
}
.card{
    width: 490px;
}
.card-content{
    height: 130px;
}